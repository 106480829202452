import React from 'react';

import Avatar from 'components/Avatar';
import { DASH } from 'config/constants';
import {
  allPersonalInterestsLabels,
  allSkillsLabels,
  PersonalInterest,
  Skill,
  TeacherStatus,
} from 'config/teacherProfiles';
import { ITeacher } from 'model/profiles';
import { useEvcTunnelForTeacher } from 'network/Classroom';
import { useDepartmentWithSchool } from 'network/graphql/Departments';
import { getEnglishFullLangName } from 'utils/miscellaneous';

import {
  ChipTypeA,
  ChipTypeB,
  GreenCircle,
  Hr,
  Label,
  ListContainer,
  YellowCircle,
  Text,
  ContentWrapper,
  EditProfileLink,
  ProfileName,
  RedCircle,
} from './styledComponents';

interface ViewContentProps {
  profile: ITeacher;
  switchToEditMode(): void;
}

export const ViewContent: React.FC<ViewContentProps> = ({ profile, switchToEditMode }) => {
  const teacherCenterName = useDepartmentWithSchool(profile?.departmentId)?.name;
  const { data: evcProxy } = useEvcTunnelForTeacher(profile.id);
  return (
    <ContentWrapper>
      <RenderBasics
        profile={profile}
        center={teacherCenterName}
        EditButton={<EditProfileLink onClick={switchToEditMode}>Edit</EditProfileLink>}
      />
      <Hr />
      <RenderLabeledValue label="Email" value={profile.email} />
      <RenderList
        label="Personal Interests"
        list={profile.personalInterests}
        transformer={(el) => allPersonalInterestsLabels[el as PersonalInterest]}
      />
      <RenderList label="Skills" list={profile.skills || []} transformer={(el) => allSkillsLabels[el as Skill]} />
      <RenderList label="Spoken Languages" list={profile.instructionLanguages} transformer={getEnglishFullLangName} />
      <RenderList label="EVC China Proxy" list={evcProxy?.description ? [evcProxy.description] : []} />
    </ContentWrapper>
  );
};

interface RenderBasicsProps {
  profile: ITeacher;
  EditButton?: JSX.Element;
  center?: string;
}

export const RenderBasics = ({ profile, center, EditButton }: RenderBasicsProps) => (
  <div style={{ display: 'flex' }}>
    <Avatar
      profilePicture={profile?.profilePicturePath}
      initials={`${profile?.firstName.charAt(0)}${profile?.lastName.charAt(0)}`.toLocaleUpperCase() || ''}
      size={80}
      fontSize={22}
      fontColor="#191919"
      avatar="profile"
    />
    <div style={{ width: 400, marginLeft: 16 }}>
      <ProfileName>
        {profile?.firstName ?? DASH} {profile?.lastName ?? DASH}
        {EditButton}
      </ProfileName>
      <div style={{ marginTop: 5, transform: 'translateY(4px)' }}>
        {profile.status === TeacherStatus.ACTIVE ? (
          <>
            <GreenCircle />
            Active
          </>
        ) : profile.status === TeacherStatus.PENDING_DOWNGRADE ? (
          <>
            <YellowCircle />
            Pending deactivation
          </>
        ) : (
          <>
            <RedCircle />
            Deactivated
          </>
        )}
      </div>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <ChipTypeA>{center || DASH}</ChipTypeA>
      </div>
    </div>
  </div>
);

export const RenderList: React.FC<{
  label: string;
  list: string[];
  transformer?: (arg: string) => string;
}> = ({ label, list = [], transformer }) => (
  <>
    <Label>{label}</Label>
    <ListContainer>
      {list?.length === 0 ? (
        <Text>{DASH}</Text>
      ) : (
        list.map((item) => <ChipTypeB key={item}>{transformer ? transformer(item) : item}</ChipTypeB>)
      )}
    </ListContainer>
  </>
);

export const RenderLabeledValue: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <>
    <Label>{label}</Label>
    <Text>{value}</Text>
  </>
);
